import React, { useState } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  Stack,
  Whisper,
  Tooltip,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import moment from "moment";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { filterParams, Table2XLSXNew, TableToPDF } from "../functions";
import AgGrid from "../component/AgGrid";
import useAPINew from "../Hooks/useAPINew";
const ViewSuppliers = () => {
  const [radio, setRadio] = useState("Supplier");
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedNameID, setselectedNameID] = useState("");
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  //const [SupplierList, setSupplierList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  //const [siteList, setSiteList] = useState([]);
  const siteList = useAPINew("GetSitesForDropdown.php", "Get");
  const SupplierList = useAPINew("GetSupplierNew.php", "Get");
  const [GST, setGST] = useState([]);
  const toaster = useToaster();
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [selectedSite, setselectedSite] = useState("");
  const handleViewModalOpen = () => setOpenDrawer(true);

  const handleViewModalClose = () => {
    setOpenDrawer(false);
  };

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const formatNumber = (number) => {
    return "₹ " + Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    //return formatNumber(params.value);
    return formatNumber(params.value);
  };
  const column = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name", minWidth: 300 },
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      {
        field: "Credit",
        title: "Credit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Debit",
        title: "Debit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "GST",
        title: "GST",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "Amount", title: "Amount", valueFormatter: currencyFormatter },
      { field: "Sitename", title: "Sitename" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );

  const column2 = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name", minWidth: 300 },
      {
        field: "Date",
        title: "Date",
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
      },
      {
        field: "Credit",
        title: "Credit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "Debit",
        title: "Debit",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      {
        field: "GST",
        title: "GST",
        valueFormatter: currencyFormatter,
        cellStyle: (params) => {
          if (params.value === 0) {
            //mark police cells as red
            return { color: "white", fontWeight: "bold" };
          }
        },
      },
      { field: "Amount", title: "Amount", valueFormatter: currencyFormatter },
      { field: "Sitename", title: "Sitename" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );
  const tooltip = (
    <Tooltip placement="right">
      {`GST Received : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit)}`}
      <br />
      {`GST Paid : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit + debit)}`}
      <br />
      {`Balance : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit - GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit - GST.Debit - debit)}`}
    </Tooltip>
  );

  const handleRadio = (e) => {
    setRadio(e);
    if (e === "Supplier") {
      SupplierList.reload();
    }
  };

  const handleDelete = (
    id,
    data = { Name: "", Date: "", Debit: "", Credit: "" }
  ) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Payment on ${
        data.Date
      } for ${data.Debit === "0" ? "Credit" : "Debit"} Amount ${
        data.Debit === "0" ? data.Credit : data.Debit
      }?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromSupplier.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchforSupplier = () => {
    setdataList([]);
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { SupplierID: selectedNameID };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
          setGST(data1.GST);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTrasactionsBySite.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySupplierSite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite, SupplierID: selectedNameID };
    //console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySiteSupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchforSupplierByDate = () => {
    setProcessing2(true);
    setdataList2([]);
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetSupplierTransactionByDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        //console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setCredit(data1.Credit);
            setDebit(data1.Debit);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>
          Suppliers Ledger -{radio === "Supplier" ? " Report by Supplier" : ""}
          {radio === "DateWise" ? " Date wise Search" : ""}
          {radio === "SiteWise" ? " Site wise Search" : ""}
          {radio === "SiteSupplierWise" ? " Site/Supplier wise" : ""}
        </h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="Supplier"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="Supplier">Supplier</Radio>
            <Radio value="DateWise">Date wise</Radio>
            <Radio value="SiteWise">Site wise </Radio>
            <Radio value="SiteSupplierWise">Site & Supplier</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "Supplier" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={SupplierList.data}
                  appearance="subtle"
                  placeholder="Select Supplier"
                  width="auto"
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplier}
                  loading={processing}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
              </Stack>
              <Stack spacing={4}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(debit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit - debit)}`}
                </div>
                {dataList.length > 0 && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={tooltip}
                  >
                    <Button>Detail</Button>
                  </Whisper>
                )}
              </Stack>
            </Stack>
          )}

          {radio === "DateWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <DateRangePicker
                  placeholder="Select Date Range"
                  cleanable={false}
                  value={selectedDateRange}
                  onChange={setselectedDateRange}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplierByDate}
                  loading={processing2}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
              </Stack>
            </Stack>
          )}
          {radio === "SiteWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList.data}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 250 }}
                  key="SrNo"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
                &nbsp; &nbsp;
                {`Balance : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit - debit)}`}
              </Stack>
            </Stack>
          )}
          {radio === "SiteSupplierWise" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList.data}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 200 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />

                <SelectPicker
                  data={SupplierList.data}
                  cleanable={false}
                  appearance="default"
                  placeholder="Select Supplier"
                  style={{ width: 224 }}
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySupplierSite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
                &nbsp; &nbsp;
                {`Balance : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit - debit)}`}
              </Stack>
            </Stack>
          )}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "Supplier" && (
          <>
            {/* <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td style={{ color: "#2d6f03" }}>
                    {a.Credit > 0 &&
                      Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Credit)}
                  </td>
                  <td style={{ color: "#ad1714" }}>
                    {a.Debit > 0 &&
                      Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Debit)}
                  </td>

                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST + a.Debit + a.Credit)}
                  </td>
                  <td>{a.Sitename}</td>
                  <td>{a.Notes}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.SupplierName,
                          Date: a.Date,
                          Debit: a.Debit,
                          Credit: a.Credit,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
            <AgGrid
              rowData={dataList}
              columns={column}
              onGridReady={handleSearchforSupplier}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "DateWise" && (
          <>
            {" "}
            {/* <Table striped bordered hover ID="TableData">
              <thead>
                <tr>
                  {column2.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList2.map((a) => (
                  <tr key={a.SrNo}>
                    <td>{a.SupplierName}</td>
                    <td>{a.Date}</td>
                    <td>{a.Type}</td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Amount)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.GST)}
                    </td>
                    <td>{a.Notes}</td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
            <AgGrid
              rowData={dataList2}
              columns={column2}
              onGridReady={handleSearchforSupplierByDate}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "SiteWise" && (
          <>
            {" "}
            {/* <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column2.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Notes}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
            <AgGrid
              rowData={dataList}
              columns={column}
              onGridReady={handleSearchBySite}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
        {radio === "SiteSupplierWise" && (
          <>
            {" "}
            {/* <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column2.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Notes}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
            <AgGrid
              rowData={dataList}
              columns={column2}
              onGridReady={handleSearchBySupplierSite}
              onRowSelectedData={setSelectedRowData}
              handleViewModalOpen={handleViewModalOpen}
              setRowStyle={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewSuppliers;
