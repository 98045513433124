import React, { useState } from "react";
import {
  Button,
  DatePicker,
  useToaster,
  Notification,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  Modal,
  Stack,
  Input,
  InputGroup,
  AutoComplete,
  DateRangePicker,
} from "rsuite";
import Header from "../component/Header";
import { Col, Grid, Row } from "rsuite";
import moment from "moment";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SearchIcon from "@rsuite/icons/Search";

import { firstDay } from "../Constants";
import { Image } from "react-bootstrap";
import { filterParams, ProperFormat, Table2XLSXNew } from "../functions";
import useAPINew from "../Hooks/useAPINew";
import AgGrid from "../component/AgGrid";
import axios from "axios";
import SideDrawer from "../component/SideDrawer";
import { Categories, Mode } from "../component/data";
import { addDays } from "date-fns";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import startOfDay from "date-fns/startOfDay";

//import { startOfToday } from "date-fns";
function getLastFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfLastFY, endOfLastFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfLastFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfLastFY = new Date(currentYear, 2, 31); // March 31st of this year
  } else {
    // From January to March
    startOfLastFY = new Date(currentYear - 2, 3, 1); // April 1st two years ago
    endOfLastFY = new Date(currentYear - 1, 2, 31); // March 31st of last year
  }

  return {
    startOfLastFY,
    endOfLastFY,
  };
}

const { startOfLastFY, endOfLastFY } = getLastFinancialYearDates();

function getCurrentFinancialYearDates() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startOfCurrentFY, endOfCurrentFY;

  if (currentMonth >= 3) {
    // From April to December
    startOfCurrentFY = new Date(currentYear, 3, 1); // April 1st of this year
    endOfCurrentFY = new Date(currentYear + 1, 2, 31); // March 31st of next year
  } else {
    // From January to March
    startOfCurrentFY = new Date(currentYear - 1, 3, 1); // April 1st of last year
    endOfCurrentFY = new Date(currentYear, 2, 31); // March 31st of this year
  }

  return {
    startOfCurrentFY,
    endOfCurrentFY,
  };
}

const { startOfCurrentFY, endOfCurrentFY } = getCurrentFinancialYearDates();

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last Fin Year",
    value: [startOfLastFY, endOfLastFY],
  },
  {
    label: "Current Fin Year",
    value: [startOfCurrentFY, endOfCurrentFY],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [
      startOfWeek(addDays(new Date(), 6)),
      endOfWeek(addDays(new Date(), 6)),
    ],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [
      startOfMonth(addMonths(new Date(), 1)),
      endOfMonth(addMonths(new Date(), 1)),
    ],
  },
];
const ViewAll = () => {
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const usersList = useAPINew("GetSupervisorUsers.php");
  const receiverList = useAPINew("GetAllBillerNew.php", "Get");
  const siteList = useAPINew("GetSitesForDropdown.php", "Get");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = React.useState([startOfCurrentFY, endOfCurrentFY]);
  const [data, setData] = useState({});
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState();
  const [desc, setDesc] = useState("");
  const [mode, setMode] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [radio, setRadio] = useState("A");
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedName, setselectedName] = useState("");
  const [selectedMethod, setselectedMethod] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [datalist, setDataList] = useState([]);
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  //const [usersList, setUsersList] = useState([]);
  const [selectedSite, setselectedSite] = useState("");
  const [billPath, setBillPath] = useState("");
  //const [siteList, setSiteList] = useState([]);
  const [billID, setBillID] = useState("");
  const [billCopy, setBillCopy] = useState("");
  const [sitename, setSitename] = useState("");
  const [films1, setFilms1] = useState([]);
  const toaster = useToaster();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleViewModalOpen = () => setOpenDrawer(true);

  const handleViewModalClose = () => {
    setOpenDrawer(false);
  };

  React.useEffect(() => {
    console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.SrNo) {
        // GetDiscussionByID(selectedRowData.UniqueID);
        var dateParts = selectedRowData.Date.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(
          dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );
        setDate(new Date(dateObject));
        setDesc(selectedRowData.Description);
        setName(selectedRowData.Name);
        setCategory(selectedRowData.Category);
        setAmount(selectedRowData.Amount);
        setSitename(selectedRowData.Sitename);
        setBillID(selectedRowData.SrNo);
        setMode(selectedRowData?.Mode || desc.split(" // ")[1]);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.SrNo, selectedRowData]);
  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  /*   const [receiverList, setReceiverList] = useState([
    {
      label: "Eugenia",
      value: "Eugenia",
      role: "Master",
    },
    {
      label: "Kariane",
      value: "Kariane",
      role: "Master",
    },
    {
      label: "Louisa",
      value: "Louisa",
      role: "Master",
    },
  ]); */

  /*   const getRecieversList = () => {
    setReceiverList([]);
    axios
      .get("https://api.laxmaninterior.in/GetAllBillers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setReceiverList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */
  /* 
  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetSupervisorUsers.php")
      .then(function (response) {
        const data1 = response.data;
        //console.log(data1);
        setUsersList(data1.Data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 */
  /*   const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
        ;
  }; */
  const formatNumber = (number) => {
    return "₹ " + Math.floor(number).toLocaleString();
  };
  const currencyFormatter = (params) => {
    //return formatNumber(params.value);
    return formatNumber(params.value);
  };

  const [column, setColumn] = useState([
    {
      field: "Name",
      title: "Name",
      minWidth: 300,
      valueFormatter: ProperFormat,
    },
    {
      field: "Date",
      title: "Date",
      type: "date",
      filter: "agDateColumnFilter",
      editable: false,
      filterParams: filterParams,
    },
    {
      field: "Amount",
      title: "Amount",
      valueFormatter: currencyFormatter,
    },
    { field: "Category", title: "Category" },
    { field: "Description", title: "Description" },
    { field: "RecipientType", title: "PayeeType" },
    { field: "SavedBy", title: "SavedBy", headerName: "Paid By" },
  ]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (radio === "A") handleGetCurrentMonth();
    }
    return () => {
      isSubscribed = false;
    };
  }, [radio]);

  const handleGetCurrentMonth = () => {
    setDataList([]);
    axios
      .get("https://api.laxmaninterior.in/GetTransactionsForCurrentMonth.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleMonthwise = () => {
    setDataList([]);
    let datatoSend = { Month: moment(selectedDate).format("YYYY-MM-DD") };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsForMonth.php",
        datatoSend
      )
      .then((response) => {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
          setDataList([]);
          setTotal(0);
          setCount(0);
        }
      })
      .catch((error) => {
        //console.log(error);
        setTotal(0);
        setCount(0);
        setDataList([]);
      });
  };

  const handlePartywise = () => {
    setDataList([]);
    let datatoSend = { NameID: selectedNameID };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByParty.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCount(data1.Count);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSupwise = () => {
    setDataList([]);
    setCredit(0);
    setDebit(0);
    setColumn([
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "Category", title: "Category" },
      { field: "Description", title: "Description" },
      { field: "TransactionType", title: "Type" },
      { field: "SavedBy", title: "SavedBy" },
    ]);

    let datatoSend = {
      NameID: selectedNameID,
      Month: selectedDate,
      Start: moment(value[0]).format("YYYY-MM-DD"),
      End: moment(value[1]).format("YYYY-MM-DD"),
    };
    axios
      .post("https://api.laxmaninterior.in/GetTransactionBySup.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setTotal(data1.Sum);
          setCredit(data1.IncomeSum);
          setDebit(data1.ExpenseSum);
        }
        if (data1.status === 0) {
          setDataList([]);
          setTotal(0);
          setCredit(0);
          setDebit(0);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const refreshAll = () => {
    handleGetCurrentMonth();
    handleMonthwise();
    handlePartywise();
    handleSupwise();
    handleSitewise();
  };
  const handleMethodWise = () => {
    setCredit(0);
    setDebit(0);
    setDataList([]);
    setColumn([
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "Category", title: "Category" },
      { field: "Description", title: "Description" },
      { field: "TransactionType", title: "Type" },
      { field: "SavedBy", title: "SavedBy" },
    ]);

    let datatoSend = { Method: selectedMethod, Month: selectedDate };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionByMethod.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          setDataList([]);
        }
      })
      .catch(function (error) {
        setDataList([]);
        console.log(error);
      });
  };

  const handleSitewise = () => {
    let datatoSend = { Sitename: selectedSite };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionBySite.php",
        datatoSend
      )
      .then(function (response) {
        const data = response.data;
        if (data.status === 1) {
          setDataList(data.Data);
          setTotal(data.Sum);
          setCount(data.Count);
        }
        if (data.status === 0) {
          setDataList([]);
          setTotal(0);
          setCount(0);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setTotal(0);
    setCount(0);
    setDataList([]);
    setRadio(e);
    if (e === "A") {
      handleGetCurrentMonth();
    }
    if (e === "B") {
      handleMonthwise();
    }
    if (e === "C") {
      setselectedName("");
      receiverList.reload();
    }
    if (e === "D") {
      setselectedName("");
      setDataList([]);
      //  getUsersList();
      usersList.reload();
    }
    if (e === "E") {
      siteList.reload();
      setselectedSite("");
      setDataList([]);
      usersList.reload();
      //getUsersList();
    }
    if (e === "F") {
      setDataList([]);
    }
  };

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const materialTransactions = () => {
    const formData = new FormData();
    formData.append("File", billCopy);
    setProcessing(true);

    if (date === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please Select transaction date.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (amount === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter bill amount.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    try {
      if (billCopy) {
        setProcessing1(true);
        axios
          .post("https://downloads.laxmaninterior.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              setProcessing(true);
              const txt = `${desc.replaceAll(
                "// ===Bill Copy Attached===",
                ""
              )} - ${mode} // ===Bill Copy Attached===`;
              const datatoSend = {
                Date: moment(date).format("YYYY-MM-DD"),
                Amount: amount,
                Month: moment(date).format("YYYY-MM-DD"),
                //Desc: txt,
                Desc: `${txt.replaceAll(mode, "")} // ${mode}`,
                Mode: mode,
                SrNo: billID,
                Category: category,
                Bill: result.Filename,
                SiteName: sitename,
              };

              axios
                .post(
                  "https://downloads.laxmaninterior.in/EditTransaction.php",
                  datatoSend,
                  {
                    headers: { "Content-type": "application/json" },
                  }
                )
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="Success"
                        duration={1000}
                      >
                        Updated successfully
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                    setEditMode(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error" duration={1000}>
                        Error occured
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  setProcessing(false);
                });
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        const datatoSend = {
          Date: moment(date).format("YYYY-MM-DD"),
          Amount: amount,
          Month: moment(date).format("YYYY-MM-DD"),
          //Desc: `${desc} - ${mode}`,
          Desc: `${desc.replaceAll(mode, "")} // ${mode}`,
          SrNo: selectedRowData.SrNo,
          Category: category,
          SiteName: sitename,
          Mode: mode,
        };

        axios
          .post(
            "https://api.laxmaninterior.in/EditTransaction.php",
            datatoSend,
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              toaster.push(
                <Notification type="success" header="Success" duration={1000}>
                  Updated successfully
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  Error occured
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
              // handleClose4();
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing(false);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      //  setProcessing1(false);
    }
  };

  const handleDelete = (data = { Name: "", Date: "", Amount: "" }) => {
    if (billID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    //confirmAlert(options);
    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${name}'s Payment on ${moment(
        date
      ).format("DD-MM-YYYY")} for transaction of Amount ${amount}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: billID };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromTransaction.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${name}'s Payment on ${date} for Amount ${amount}`,
                    `Deleted (${name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${name}'s Payment on ${date} for Amount Amount ${amount} ?`,
                    `Deleted (${name}) Transaction`,
                    "Successfully deleted"
                  );
                  confirmAlert.close(); // Close the alert
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete (${name}) Transaction`,
                    "Error in Deleting from Transaction"
                  );
                  confirmAlert.close(); // Close the alert
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from (${name}) Transaction`,
                  "Error in Deleting from Transaction"
                );
              });
            onclose();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  function RemoveBill() {
    console.log(data);
    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to remove this bill?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: billID };

            axios
              .post(
                "https://api.laxmaninterior.in/RemoveBillCopy.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Bill Copy on ${data.Date} for Amount ${data.Amount}`,
                    `Deleted from Material(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Bill Copy on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Material(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  refreshAll();
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>
          {radio === "A" &&
            `Current Month (${moment(firstDay).format("MMM-YYYY")})`}
          {radio === "B" && `${moment(selectedDate).format("MMM-YYYY")}`}
          {radio === "C" &&
            (selectedName ? `Party Wise - ${selectedName}` : "Party Wise")}
          {radio === "D" &&
            (selectedName
              ? `Supervisor Wise - ${selectedName}`
              : "Supervisor Wise")}
          {radio === "E" &&
            (selectedSite ? `Material - (${datalist.length})` : "Material")}
          {radio === "F" &&
            (selectedMethod
              ? `${selectedMethod} - (${datalist.length})`
              : "Transactions")}
        </h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Current Month</Radio>
            <Radio value="B">Month Wise</Radio>
            <Radio value="C">Party View</Radio>
            <Radio value="D">Supervisor</Radio>
            <Radio value="E">Material</Radio>
            <Radio value="F">Bank/Cash</Radio>
          </RadioGroup>
          &nbsp; &nbsp; &nbsp;
          <Table2XLSXNew
            tableID="TableData"
            exportFileName={`ExportTransaction-${moment().format(
              "DD-MM-YY-HH-MM-SS"
            )}`}
          />
          <hr />
        </Form.Group>

        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleGetCurrentMonth}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </div>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    {`Total Amount : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {`No of Transactions : ${count}`}
                  </div>
                </div>
              </div>
            </>
          )}
          {radio === "B" && (
            <>
              <Stack spacing={20}>
                <Stack spacing={4}>
                  <DatePicker
                    oneTap
                    cleanable={false}
                    format="MMM-yyyy"
                    defaultValue={firstDay}
                    ranges={[]}
                    onSelect={setselectedDate}
                    style={{ width: 130 }}
                  />
                  <Button
                    color="blue"
                    appearance="primary"
                    onClick={handleMonthwise}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </Stack>
                <div style={{ flex: 1, justifyContent: "center" }}></div>
                <Stack spacing={5}>
                  <span style={{ fontWeight: "bold" }}>Total Amount : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(total)}`}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    No of Transactions :{" "}
                  </span>{" "}
                  {`${count}`}
                </Stack>
              </Stack>
            </>
          )}

          {radio === "C" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifuContent: "space-between",
              }}
            >
              <Stack spacing={5} style={{ flex: 1 }}>
                <SelectPicker
                  cleanable={false}
                  data={receiverList.data}
                  appearance="default"
                  placeholder="Select Party"
                  style={{ width: 224 }}
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    console.log(Item);
                    setselectedName(e);
                    setselectedNameID(Item.id);
                  }}
                />

                <Button
                  color="blue"
                  appearance="default"
                  onClick={handlePartywise}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <div style={{ flex: 1, justifyContent: "center" }}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Total Amount : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(total)}`}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    No of Transactions :{" "}
                  </span>{" "}
                  {`${count}`}
                </div>
              </div>
            </div>
          )}

          {radio === "D" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <Stack spacing={5} style={{ flex: 1 }}>
                  <SelectPicker
                    cleanable={false}
                    data={usersList.data}
                    appearance="default"
                    placeholder="Select Supervisor"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                  <DateRangePicker
                    ranges={predefinedRanges}
                    value={value}
                    onChange={setValue}
                    cleanable={false}
                    showMeridian
                    format="dd-MM-yyyy"
                    defaultCalendarValue={[
                      new Date("2024-08-01"),
                      new Date("2024-08-31"),
                    ]}
                  />
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleSupwise}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </Stack>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <Stack spacing={5}>
                    <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(credit)}`}
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(debit)}`}
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(credit - debit)}`}
                  </Stack>
                </div>
              </div>
            </>
          )}

          {radio === "E" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <Stack spacing={5} style={{ flex: 1 }}>
                  <SelectPicker
                    cleanable={false}
                    data={siteList.data}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setselectedSite(e);
                    }}
                    value={selectedSite}
                  />
                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleSitewise}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </Stack>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Total : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(total)}`}
                  </div>
                </div>
              </div>
            </>
          )}
          {radio === "F" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <Stack spacing={5} style={{ flex: 1 }}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={[
                      { label: "Cash", value: "Cash" },
                      { label: "Bank", value: "Bank" },
                      { label: "Canara Bank", value: "Canara Bank" },
                      { label: "Bandhan Bank", value: "Bandhan Bank" },
                    ]}
                    appearance="default"
                    placeholder="Select Method"
                    defaultValue={selectedMethod}
                    style={{ width: 200 }}
                    onSelect={(e, Item) => {
                      setselectedMethod(e);
                    }}
                    value={selectedMethod}
                  />

                  <DatePicker
                    cleanable={false}
                    oneTap
                    format="MMM-yyyy"
                    defaultValue={firstDay}
                    onSelect={(e) => setselectedDate(e)}
                    style={{ width: 130 }}
                  />

                  <Button
                    color="blue"
                    appearance="default"
                    onClick={handleMethodWise}
                  >
                    <SearchIcon style={{ margin: "5px" }} /> Search
                  </Button>
                </Stack>
                <div style={{ flex: 1, justifyContent: "center" }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Received : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(credit)}`}
                    &nbsp; &nbsp; &nbsp;
                    <span style={{ fontWeight: "bold" }}>Paid : </span>{" "}
                    {`${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(debit)}`}
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>

        <div>
          <Modal open={open} onClose={handleClose} size="lg">
            <Modal.Header>
              <Modal.Title>Bill </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image style={{ resize: "contain" }} src={billPath} />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={RemoveBill} appearance="primary">
                Remove Bill
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className="InputContainer">
        {/*  <Table id="TableData" striped bordered hover>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datalist.map((a) => (
              <tr key={a.SrNo}>
                <td
                  onClick={() =>
                    ShowBill(a.Bill, {
                      Name: a.Name,
                      Date: a.Date,
                      Amount: a.Amount,
                      ID: a.SrNo,
                    })
                  }
                  style={{ cursor: `${a.Bill ? "pointer" : "cursor"}` }}
                >
                  {a.Name}
                </td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>{a.Category}</td>
                <td>{a.Description}</td>
                <td>
                  {radio === "D"
                    ? a.TransactionType
                    : radio === "F"
                    ? a.Type
                    : a.RecipientType}
                </td>
                <td>{a.SavedBy}</td>
                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() =>
                      handleDelete(a.SrNo, {
                        Name: a.Name,
                        Date: a.Date,
                        Amount: a.Amount,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <AgGrid
          rowData={datalist}
          columns={column}
          onGridReady={handleMonthwise}
          onRowSelectedData={setSelectedRowData}
          handleViewModalOpen={handleViewModalOpen}
        />
      </div>
      <SideDrawer
        open={openDrawer}
        onClose={handleViewModalClose}
        title="View / Edit Transaction"
      >
        <div className="row">
          <Grid fluid style={{ padding: 1 }} gutter={4}>
            <Row className="show-grid">
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Name : </label>
                  <Input
                    readOnly={true}
                    value={name}
                    onChange={setName}
                    style={{
                      outline: "none",
                      padding: "5px 10px 5px 10px",
                    }}
                    placeholder="Enter Name"
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Date : </label>
                  <br />
                  <DatePicker
                    readOnly={!editMode}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    oneTap
                    format="dd-MM-yyyy"
                    style={{ width: "100%", fontSize: 17 }}
                    value={date}
                    onChange={setDate}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Category : </label>
                  <br />
                  <SelectPicker
                    readOnly={!editMode}
                    searchable={false}
                    cleanable={false}
                    style={{ width: "100%" }}
                    data={Categories}
                    appearance="default"
                    placeholder="Category"
                    value={category}
                    onChange={setCategory}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Amount : </label>
                  <br />
                  <InputGroup>
                    <InputGroup.Addon>₹</InputGroup.Addon>
                    <Input
                      value={amount}
                      onChange={setAmount}
                      readOnly={!editMode}
                    />
                    <InputGroup.Addon>.00</InputGroup.Addon>
                  </InputGroup>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Desc : </label>
                  <Input
                    readOnly={!editMode}
                    value={desc}
                    onChange={setDesc}
                    style={{ padding: 5, outline: "none" }}
                    placeholder="Enter Description"
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Site Name : </label>
                  <AutoComplete
                    readOnly={!editMode}
                    data={films1}
                    value={sitename}
                    onChange={(e) => setSitename(e)}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>Payment Mode : </label>
                  <SelectPicker
                    readOnly={!editMode}
                    cleanable={false}
                    searchable={false}
                    data={Mode}
                    appearance="default"
                    placeholder="Select payment mode"
                    style={{ width: "100%" }}
                    key="id"
                    value={mode || desc.split(" // ")[1]}
                    onChange={(e) => {
                      setMode(e);
                    }}
                  />
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <label>{`${processing1 ? "Bill Uploading " : "Bill"}`}</label>

                  <input
                    disabled={!editMode}
                    type="file"
                    name="file"
                    onChange={(e) => setBillCopy(e.target.files[0])}
                  ></input>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  {!editMode && (
                    <>
                      <Button
                        style={{ padding: "10px 12px 10px 12px", width: 100 }}
                        appearance="primary"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                  {editMode && (
                    <>
                      <Button
                        style={{ padding: "10px 12px 10px 12px", width: 100 }}
                        appearance="primary"
                        color="blue"
                        onClick={materialTransactions}
                      >
                        Save
                      </Button>
                    </>
                  )}
                  &nbsp; &nbsp;
                  {!editMode && (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  )}
                  &nbsp; &nbsp;
                  {editMode && (
                    <Button
                      style={{ padding: "10px 12px 10px 12px", width: 100 }}
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ margin: "6px 0px 6px 0px" }}
              >
                <Row gutter={3} style={{ marginBottom: 10 }}>
                  <img
                    width={300}
                    alt="img"
                    src={`https://Downloads.laxmaninterior.in/upload/${selectedRowData?.Bill}`}
                  />
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </SideDrawer>
    </div>
  );
};

export default ViewAll;
