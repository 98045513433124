import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Input,
  Form,
  InputGroup,
  Modal,
  SelectPicker,
  useToaster,
  Notification,
  IconButton,
  Stack,
} from "rsuite";
import Table from "react-bootstrap/Table";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; //
import Header from "../component/Header";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import axios from "axios";

import DetailIcon from "@rsuite/icons/Detail";
import * as XLSX from "xlsx";
import moment from "moment";
import sendEmail from "../component/sendEmail";
import { Table2XLSXNew, number2text } from "../functions";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AddClientTransaction = () => {
  const column = React.useMemo(
    () => [
      { field: "ClientName", title: "Client Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "GST", title: "GST" },
      { field: "Type", title: "Type" },
      { field: "Note", title: "Note" },

      { field: "Delete", title: "Delete" },
    ],
    []
  );
  const [clientList, setClientList] = useState([]);
  const [dataList, setDataList] = useState([]);
  /* const [open, setOpen] = React.useState(false); */
  const [open1, setOpen1] = React.useState(false);
  const [processing, setProcessing] = useState(false);

  const [numberText, setNumberText] = useState("");
  const [value, setValue] = React.useState({
    ClientName: "",
    Desc: "",
    ClientID: "",
    Date: new Date(),
    Type: "",
    Amount: "",
    Note: "",
    GST: "",
    Mode: "",
  });
  const [sorting, setSorting] = useState({
    key: "ClientName",
    ascending: true,
  });

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setNumberText(number2text(value.Amount));
    return () => {
      isSubscribed = false;
    };
  }, [value.Amount]);

  useEffect(() => {
    // Copy array to prevent data mutation
    const dataListCopy = [...dataList];

    // Apply sorting
    const sortedCurrentUsers = dataListCopy.sort((a, b) => {
      return a[sorting.key].localeCompare(b[sorting.key]);
    });

    // Replace currentUsers with sorted currentUsers
    setDataList(
      // Decide either currentUsers sorted by ascending or descending order
      sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
    );
  }, [sorting]);

  const toaster = useToaster();

  /* const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }; */
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleRefresh = () => {
    getClientList();
    axios
      .get("https://api.laxmaninterior.in/GetAllClientTransactions.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
        }
        if (data1.status === 0) {
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  const getClientList = () => {
    setClientList([]);
    axios
      .get("https://api.laxmaninterior.in/GetClients.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setClientList((a) => {
              return [
                ...a,
                { id: r.SrNo, value: r.ClientName, label: r.ClientName },
              ];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const SaveData = () => {
    setProcessing(true);

    if (value.ClientName === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter client name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Type === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter transaction type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (value.Amount === "" || value.Amount === 0) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter valid amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Date === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter data
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    axios
      .post(
        "https://api.laxmaninterior.in/AddClientTransactions.php",
        { ...value, Date: moment(value.Date).format("YYYY-MM-DD") },
        { headers: { "Content-type": "application/json" } }
      )
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );
          handleRefresh();
          clearInput();
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {result.error}
            </Notification>,
            { placement: "topEnd" }
          );
        }

        setProcessing(false);
        clearInput();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = (
    id,
    data = { Name: "", Date: "", Amount: "", Type: "" }
  ) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s ${data.Type} on ${data.Date} for Amount ${data.Amount} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromClient.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Client(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Client(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Client(${data.Name}) Transaction`,
                    "Error in Deleting from Client Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Client(${data.Name}) Transaction`,
                  "Error in Deleting from Client Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  function clearInput() {
    setValue({
      ClientName: "",
      ClientID: "",
      Date: new Date(),
      Type: "",
      Amount: "",
      Note: "",
      GST: "",
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add New</h4>
      </Header>
      <div className="Subbody">
        <div style={{ flexDirection: "column" }}>
          <Stack spacing={7}>
            <SelectPicker
              cleanable={false}
              data={clientList}
              appearance="default"
              placeholder="Select Client"
              style={{ width: 224 }}
              value={value.value}
              onSelect={(e, Item) => {
                setValue((a) => {
                  return { ...a, ClientName: Item.value, ClientID: Item.id };
                });
              }}
            />
            <DatePicker
              format="dd-MM-yyyy"
              cleanable={false}
              oneTap
              appearance="default"
              placeholder="Select Date"
              style={{ width: 150 }}
              value={value.Date}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Date: e };
                });
              }}
            />
            <SelectPicker
              cleanable={false}
              data={[
                { id: 1, value: "Payment", label: "Payment" },
                { id: 2, value: "Invoice", label: "Invoice" },
              ]}
              appearance="default"
              placeholder="Type"
              searchable={false}
              style={{ width: 120 }}
              value={value.Type}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Type: e };
                });
              }}
            />
            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>(₹)</InputGroup.Addon>
              <Input
                value={value.Amount}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, Amount: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>GST (₹)</InputGroup.Addon>
              <Input
                value={value.GST}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, GST: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
          </Stack>
          <br />
          <Stack spacing={7}>
            <SelectPicker
              cleanable={false}
              data={[
                { id: 1, value: "Cash", label: "Cash" },
                { id: 2, value: "Canara Bank", label: "Canara Bank" },
                { id: 2, value: "Bandhan Bank", label: "Bandhan Bank" },
                { id: 2, value: "BOB Bank", label: "BOB Bank" },
              ]}
              appearance="default"
              placeholder="Select payment mode"
              searchable={false}
              style={{ width: 200 }}
              value={value.Mode}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Mode: e };
                });
              }}
            />
            <Button appearance="link" onClick={handleOpen1}>
              <DetailIcon style={{ margin: "5px" }} /> Note
            </Button>
            <IconButton
              color="green"
              appearance="primary"
              onClick={SaveData}
              loading={processing}
              icon={<AddOutlineIcon />}
            >
              Add
            </IconButton>
            <Button color="green" appearance="primary" onClick={handleRefresh}>
              <ReloadIcon style={{ margin: "5px" }} />
            </Button>
            <Table2XLSXNew />
          </Stack>
          <hr />
          <Stack>
            <div>{numberText}</div>
          </Stack>
        </div>
      </div>
      {/* Description: ===================================================== */}

      <div>
        <Modal open={open1} onClose={handleClose1} size="xs">
          <Modal.Header>
            <Modal.Title>New Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group controlId="textarea-9">
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  rows={5}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={value.Note}
                  onChange={(e) => {
                    setValue((a) => {
                      return { ...a, Note: e };
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose1} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose1} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`List of Transactions (${moment().format("MMM-YYYY")})`}</h5>
      </Header>
      <div className="InputContainer">
        <Table striped bordered hover>
          <thead>
            <tr>
              {column.map((a) => (
                <th
                  key={a.field}
                  onClick={() => {
                    console.log(a.field);
                    applySorting([a.field], !sorting.ascending);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {a.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.ClientName}</td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.GST)}
                </td>
                <td>{a.Type}</td>
                <td>{a.Note}</td>
                {/* <td width={60}>
                  <IconButton
                    icon={<EditIcon />}
                    color="blue"
                    disabled
                    appearance="primary"
                    circle
                    onClick={() => {} }
                  />
                </td> */}
                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() =>
                      handleDelete(a.SrNo, {
                        Name: a.ClientName,
                        Date: a.Date,
                        Amount: a.Amount,
                        Type: a.Type,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddClientTransaction;
