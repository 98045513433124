import { useState, useEffect, useCallback } from "react";
import axios2 from "../api/axios2";

const useAPINew = (url, Type = "Post") => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (Type === "Post") {
        const response = await axios2.post(url);
        setData(response.data.Data);
      } else {
        const response = await axios2.get(url);
        setData(response.data.Data);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, reload: fetchData };
};

export default useAPINew;
